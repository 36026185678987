export const isValidURL = (string) => {
  const urlPattern =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;

  return urlPattern.test(string);
};

export const isValidDateTime = (dateTimeString) => {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}\+\d{2}:\d{2}$/;
  return regex.test(dateTimeString);
};
