import React, { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ENDPOINT_PART_REQUESTS } from "../routes";
import { makeAuthenticatedRequest } from "../utils";
import { toast, ToastContainer } from "react-toastify";

export default function PartRequestsPage() {
  const [showFilters, setShowFilters] = useState(false);
  const filtersRef = useRef(null);

  // part requests
  let [searchParams, setSearchParams] = useSearchParams();
  const [partRequests, setPartRequests] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [statusCounts, setStatusCounts] = useState([]);
  const currentPage = parseInt(searchParams.get("page") || 1);
  const [selectedStatuses, setSelectedStatuses] = useState({});
  const [showing, setShowing] = useState({});
  const [search, setSearch] = useState(searchParams.get("s") || "");
  const currentUrlParams = new URLSearchParams(window.location.search);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentPartRequest, setCurrentPartRequest] = useState({});

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  const getPartRequests = async () => {
    try {
      const url = `/oms/part_requests/?${searchParams}`;
      const response = await makeAuthenticatedRequest(url);
      const { results, per_page, count, status_counts } = await response.json();
      setPartRequests(results);
      setPerPage(per_page);
      setCount(count);
      setStatusCounts(status_counts);
    } catch (error) {
      toast.error("An error occured while fetching part requests!");
    }
  };

  useEffect(() => {
    getPartRequests();
  }, [searchParams]);

  // Pagination
  const handleNextPage = () => {
    const totalPages = Math.ceil(parseInt(count) / parseInt(perPage));
    const nextPage = currentPage + 1;
    if (nextPage > totalPages) return;
    currentUrlParams.set("page", nextPage);
    setSearchParams(currentUrlParams);
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage < 1) return;
    currentUrlParams.set("page", prevPage);
    setSearchParams(currentUrlParams);
  };

  useEffect(() => {
    const updateShowing = () => {
      const showingFrom = (currentPage - 1) * perPage + 1;
      const showingTo = showingFrom + perPage - 1;
      setShowing({ ...showing, from: showingFrom, to: showingTo });
    };
    updateShowing();
  }, [currentPage]);

  // Filters
  const handleOutsideClick = (e) => {
    if (filtersRef?.current && !filtersRef.current.contains(e.target)) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    const run = () => {
      document.addEventListener("click", handleOutsideClick);
    };
    run();
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleFilterChange = (e) => {
    const statusName = e.target.name;
    if (selectedStatuses[statusName]) {
      setSelectedStatuses({ ...selectedStatuses, [statusName]: false });
    } else {
      setSelectedStatuses({ ...selectedStatuses, [statusName]: true });
    }
  };

  useEffect(() => {
    const updateURLParams = () => {
      const statusArr = [];
      for (const property in selectedStatuses) {
        if (selectedStatuses[property]) {
          statusArr.push(property);
        }
      }
      if (statusArr.length) {
        const statusCsv = statusArr.join(",");
        currentUrlParams.set("page", 1);
        currentUrlParams.set("status", statusCsv);
      } else {
        currentUrlParams.delete("status");
      }
      setSearchParams(currentUrlParams);
    };
    updateURLParams();
  }, [selectedStatuses]);

  // Search
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    currentUrlParams.set("s", search);
    currentUrlParams.set("page", 1);
    setSearchParams(currentUrlParams);
  };

  const renderStatus = (statusName) => {
    switch (statusName) {
      case "Placed":
        return (
          <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
            {statusName}
          </span>
        );

      case "Outsourcing":
        return (
          <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
            {statusName}
          </span>
        );

      case "Fulfilled":
        return (
          <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400">
            {statusName}
          </span>
        );

      case "Accepted":
        return (
          <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
            {statusName}
          </span>
        );

      case "Cancelled":
        return (
          <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
            {statusName}
          </span>
        );

      default:
        return statusName;
    }
  };

  const renderStatusCount = (statusCount) => {
    switch (statusCount?.name) {
      case "Placed":
        return (
          <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400">
            {statusCount?.name} ({statusCount?.value})
          </span>
        );

      case "Outsourcing":
        return (
          <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400">
            {statusCount?.name} ({statusCount?.value})
          </span>
        );

      case "Fulfilled":
        return (
          <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400">
            {statusCount?.name} ({statusCount?.value})
          </span>
        );

      case "Accepted":
        return (
          <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400">
            {statusCount?.name} ({statusCount?.value})
          </span>
        );

      case "Cancelled":
        return (
          <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400">
            {statusCount?.name} ({statusCount?.value})
          </span>
        );

      default:
        return (
          <span>
            {statusCount?.name} ({statusCount?.value})
          </span>
        );
    }
  };

  const updatePartRequestStatus = async (newStatus, partRequestID) => {
    const params = {
      status: newStatus, //'Placed', 'Accepted', 'Fulfilled', 'Cancelled'
    };
    try {
      const response = await makeAuthenticatedRequest(
        `/oms/part_requests/${partRequestID}/`,
        "POST",
        JSON.stringify(params)
      );
      const data = await response.json();
      toast.success("Part request succesfully updated");
      setUpdateStatus(false);
      getPartRequests();
    } catch (error) {
      toast.error("Something went wrong");
    } 
  };

  const handleEditStatus = (partRequest) => {
    setUpdateStatus(true);
    setCurrentPartRequest(partRequest);
  };

  return (
    <>
      <div className="flex w-full flex-col h-full  gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
        <p className="font-satoshi text-xl font-semibold">Part Requests</p>

        <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div class="w-full md:w-1/2">
              <form class="flex items-center" onSubmit={handleSearchSubmit}>
                <label for="simple-search" class="sr-only">
                  Search
                </label>
                <div class="relative w-full">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewbox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    id="simple-search"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search"
                    required=""
                  />
                </div>
              </form>
            </div>
            <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div
                class="flex items-center space-x-3 w-full md:w-auto"
                ref={filtersRef}
              >
                <button
                  id="filterDropdownButton"
                  data-dropdown-toggle="filterDropdown"
                  class="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  type="button"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    class="h-4 w-4 mr-2 text-gray-400"
                    viewbox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Filter
                  <svg
                    class="-mr-1 ml-1.5 w-5 h-5"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    />
                  </svg>
                </button>

                {showFilters && (
                  <div
                    id="filterDropdown"
                    class="absolute top-16 right-0 z-10 w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700"
                  >
                    <h6 class="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                      Choose status
                    </h6>
                    <ul
                      class="space-y-2 text-sm"
                      aria-labelledby="filterDropdownButton"
                    >
                      {statusCounts?.map((statusCount, index) => (
                        <li class="flex items-center">
                          <input
                            checked={selectedStatuses[statusCount?.name]}
                            onChange={handleFilterChange}
                            id={index}
                            name={statusCount?.name}
                            type="checkbox"
                            class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                          <label
                            for={index}
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                          >
                            {renderStatusCount(statusCount)}
                            {/* {statusCount?.name} ({statusCount?.value}) */}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-4 py-3">
                    Request #
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Part Name
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Customer
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Part No.
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Vin No.
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Date
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {partRequests?.map((partRequest, index) => (
                  <tr class="border-b dark:border-gray-700" key={index}>
                    <th
                      scope="row"
                      class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <Link to={`${ENDPOINT_PART_REQUESTS}/${partRequest?.id}`}>
                        <span className="hover:underline">
                          SP-{partRequest?.id}
                        </span>
                      </Link>
                    </th>
                    <td class="px-4 py-3">{partRequest?.name}</td>
                    <td class="px-4 py-3">
                      {partRequest?.passport?.first_name}
                    </td>
                    <td class="px-4 py-3">{partRequest?.part_number || "-"}</td>
                    <td class="px-4 py-3">{partRequest?.vin_number || "-"}</td>
                    <td class="px-4 py-3">
                      {formatDate(partRequest?.date_created)}
                    </td>
                    <td class="px-4 py-3">
                      {renderStatus(partRequest?.status)}
                    </td>

                    <td class="px-4 py-3">
                      <button
                        onClick={() => handleEditStatus(partRequest)}
                        type="button"
                        class="text-gray-400 hover:text-white  hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-md text-sm p-1 text-center dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <nav
            class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
              Showing{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {showing?.from}-{showing?.to}{" "}
              </span>
              of{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {count}
              </span>
            </span>
            <ul class="inline-flex items-stretch -space-x-px">
              <li>
                <button
                  onClick={handlePrevPage}
                  class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span class="sr-only">Previous</span>
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </li>

              <li>
                <button
                  onClick={handleNextPage}
                  class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span class="sr-only">Next</span>
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {updateStatus && (
        <>
          <div
            tabindex="-1"
            class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full bg-gray-900 bg-opacity-50"
          >
            <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
              <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Update status
                  </h3>
                  <button
                    onClick={() => setUpdateStatus(false)}
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <form action="#">
                  <div class="grid gap-4 mb-4">
                    <div>
                      <label
                        for="status"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        onChange={(e) =>
                          updatePartRequestStatus(
                            e.target.value,
                            currentPartRequest?.id
                          )
                        }
                        id="status"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        {statusCounts?.map((status, index) => (
                          <option
                            selected={
                              status?.name == currentPartRequest?.status
                            }
                            key={index}
                            value={status?.name}
                          >
                            {status?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
