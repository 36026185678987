import Compressor from "compressorjs";
import { auth, storage } from "../firebaseUtils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { makeid, getFirebaseTokenThenSignin } from "../utils";

const handleAuth = async () => {
  const user = auth.currentUser;
  if (!user) {
    await getFirebaseTokenThenSignin();
  }
};

export const uploadFile = async ({ bucketName, file }) => {
  await handleAuth();

  // 1. If no file, return
  if (file === "" || file === null) return "";
  // 2. Put the file into bucketName
  const fileID = makeid(20);
  const storageRef = ref(storage, `/${bucketName}/${fileID}.webp`);
  // const file = await fetch(uploadedImage).then(r => r.blob()).then(blobFile => new File([blobFile], fileID, { type: blobFile.type }))
  // const options = {
  //    maxSizeMB: 0.1,
  //    maxWidthOrHeight: 640,
  //    useWebWorker: true
  // }
  // const compressedFile = await imageCompression(file, options);
  await uploadBytesResumable(storageRef, file);
  let downloadURL = null;
  await getDownloadURL(storageRef).then((fileURL) => (downloadURL = fileURL));
  return downloadURL;
};

export const uploadFiles = async ({ files, bucketName }) => {
  // 1. If no file, return
  if (files.length === 0) return [];
  // 2. Create an array to store all download URLs
  let fileUrls = [];
  // 3. Loop over all the files
  for (var i = 0; i < files.length; i++) {
    // 3A. Get a file to upload
    const file = files[i];
    // 3B. handleFileUploadOnFirebaseStorage function is in above section
    const downloadFileResponse = await uploadFile({ bucketName, file });
    // 3C. Push the download url to URLs array
    fileUrls.push(downloadFileResponse);
  }
  return fileUrls;
};

export const compresedImage = (file) =>
  new Promise((resolve) => {
    new Compressor(file, {
      minWidth: 640,
      maxHeight: 640,
      mimeType: "image/webp",
      success(result) {
        resolve(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  });
