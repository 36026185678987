import React, { useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { makeAuthenticatedRequest } from "../utils";
import { isValidURL, isValidDateTime } from "../utils/misc";
import { useParams } from "react-router-dom";
import { uploadFiles } from "../utils/firebaseStorage";

export const OrderMeta = ({ orderData }) => {
  let { orderID } = useParams();

  // File Uploads
  const [newFiles, setNewFiles] = useState({});

  //   custom fields
  const [addField, setAddField] = useState(false);
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldType, setNewFieldType] = useState("");
  const [addingField, setAddingField] = useState(false);
  const [newTextValue, setNewTextValue] = useState("");

  //   creatable select
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const fieldTypes = [
    { value: "text", label: "Text" },
    { value: "attributes", label: "Attributes" },
    { value: "file", label: "File" },
  ];

  const [orderMeta, setOrderMeta] = useState(null);
  const attributes = orderData?.attributes;
  const attributeKeys = Object.keys(attributes);

  const renderAttributes = (attrKey) => {
    const rawAttrs = attributes[attrKey];
    const renderedAttrs = [];
    let i = 1;

    for (const element of rawAttrs) {
      if (isValidURL(element)) {
        renderedAttrs.push(
          <>
            <a
              target="_blank"
              className="flex font-bold text-blue-400 dark:text-blue-500 hover:underline"
              href={element}
            >
              [Link {i}]
            </a>
            &nbsp; &nbsp;
          </>
        );

        i += 1;
      } else if (isValidDateTime(element)) {
        const date = new Date(element);
        renderedAttrs.push(
          `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        );
      } else {
        renderedAttrs.push(element);
      }
    }

    return renderedAttrs;
  };

  // This function will be phased out
  // It is only here to make sure data from previous
  // order meta implementation is rendered
  const getOrderMeta = async (orderID) => {
    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/meta/?order_id=${orderID}`
      );

      const { results } = await response.json();
      let processedResults = [];

      const notDates = results?.filter((result) => {
        return !result?.slug?.startsWith("date");
      });

      notDates?.map((notDate) => {
        return processedResults.push(notDate);
      });

      const dates = results.filter((result) => {
        return result?.slug?.startsWith("date");
      });

      let newDates = dates.map((date) => {
        const values = date?.values;

        const dateValues = values.map((value) => {
          const date = new Date(value);
          return date.toLocaleString();
        });

        return {
          ...date,
          values: dateValues,
        };
      });

      newDates.map((date) => {
        return processedResults.push(date);
      });

      setOrderMeta(processedResults);
    } catch (error) {
      toast.error("An error occurred while getting order meta.");
    }
  };

  useEffect(() => {
    getOrderMeta(orderData?.id);
  }, [orderData]);

  const orderMetaWithoutImages = useMemo(() => {
    return orderMeta?.filter((meta) => {
      return meta?.slug !== "delivery-proof";
    });
  }, [orderMeta]);

  const deliveryProof = useMemo(() => {
    return orderMeta?.filter((meta) => {
      return meta?.slug === "delivery-proof";
    });
  }, [orderMeta]);

  // Creatable select
  const components = {
    DropdownIndicator: null,
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  // File Uploads
  const handleNewFileChange = async (e) => {
    if (e.target.files && e.target.files.length) {
      setNewFiles(e.target.files);
    }
  };

  const handleSubmit = async (e) => {
    let payload = {};

    e.preventDefault();
    setAddingField(true);

    if (!newFieldType) {
      toast.error("Select a field type!");
      setAddingField(false);
      return;
    }

    if (!newFieldName) {
      toast.error("A valid field name is required!");
      setAddingField(false);
      return;
    }

    // handle text input
    if (newFieldType == "text") {
      payload = { ...payload, [newFieldName]: [newTextValue] };
    }

    // handle multi valued attributes
    if (newFieldType == "attributes") {
      if (!value?.length) {
        toast.error("Add atleast one attribute to proceed!");
        setAddingField(false);
        return;
      }
      payload = { ...payload, [newFieldName]: value?.map((v) => v?.value) };
    }

    // handle file
    if (newFieldType == "file" && newFiles.length) {
      let fileUrls = []
      try {
        const bucketName = `/orders/${orderID}/files/`;
        fileUrls = await uploadFiles({ files: newFiles, bucketName });
        toast.success("Files uploaded successfuly!");
        payload = { ...payload, [newFieldName]: fileUrls };
      } catch (error) {
        toast.error("An error occured while uploading files!");
        console.log("ERROR", error);
      }
    }

    const url = `/oms/order/${orderID}/attributes/`;
    const requestPayload = JSON.stringify(payload);
    try {
      await makeAuthenticatedRequest(url, "POST", requestPayload);
      toast.success("Attributes added successfully!");
    } catch (error) {
      toast.error("An error occured while creating order attributes!");
      console.log(error);
    }

    setAddingField(false);
    setAddField(false);
    setNewFieldName("");
    setNewFieldType("");
    setValue([]);
  };

  return (
    <>
      {" "}
      <div className="border border-[#E5E6E9] rounded-lg py-2 shadow-sm">
        <div className="flex flex-row justify-between">
          <div>
            <p className="font-satoshi text-[#030A1A] px-4 font-semibold text-[18px]">
              Other Details
            </p>
          </div>

          <div className="py-2 pr-4 hover:cursor-pointer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
              onClick={() => setAddField(true)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </div>
        </div>

        <div className="border-b w-full border-[#E5E6E9]"></div>

        {attributeKeys.length > 0 && (
          <div className="p-2 w-full  mt-4 rounded-lg  ">
            <div className="w-full overflow-x-scroll hide-scrollbar">
              <table class="table-auto w-full">
                <tbody>
                  {attributeKeys?.map((attrKey) => (
                    <tr className="p-2 border-b border-b-gray-200 relative">
                      <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                        <p className="font-satoshi text-[12px] uppercase">
                          {attrKey}
                        </p>
                      </td>
                      <td className="p-4 flex flex-row text-xs md:text-[12px] text-left font-satoshi">
                        {renderAttributes(attrKey)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {orderMetaWithoutImages?.length > 0 && (
          <div className="p-2 w-full  mt-4 rounded-lg  ">
            <div className="w-full overflow-x-scroll hide-scrollbar">
              <table class="table-auto w-full">
                <tbody>
                  {orderMetaWithoutImages?.map((meta) => (
                    <tr className="p-2 border-b border-b-gray-200 relative">
                      <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                        <p className="font-satoshi text-[12px] uppercase">
                          {meta?.name}
                        </p>
                      </td>
                      <td className="p-4 text-xs md:text-[12px] text-left font-satoshi">
                        {meta?.values?.join(" ,")}
                      </td>
                    </tr>
                  ))}
                  {deliveryProof?.map((meta) => (
                    <tr className="p-2 border-b border-b-gray-200 relative">
                      <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                        <p className="font-satoshi text-[12px] uppercase">
                          {meta?.name}
                        </p>
                      </td>
                      <td className="p-4 text-xs md:text-[12px] text-left font-satoshi">
                        {meta?.values?.map((value, i) => (
                          <div className="my-2">
                            <a
                              className="text-blue-500 hover:underline mr-4"
                              target="_blank"
                              href={value}
                              rel="noopener noreferrer"
                            >
                              Image {i + 1}
                            </a>
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {addField && (
        <>
          <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full bg-gray-900 bg-opacity-50"
          >
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Add Custom Field
                  </h3>
                  <button
                    type="button"
                    onClick={() => setAddField(false)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div className="w-full">
                      <label
                        htmlFor="brand"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Field Name
                      </label>
                      <input
                        type="text"
                        name="field_name"
                        value={newFieldName}
                        onChange={(e) => setNewFieldName(e.target.value)}
                        id="brand"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Name of your custom field"
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="category"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Field Type
                      </label>
                      <select
                        required
                        onChange={(e) => setNewFieldType(e.target.value)}
                        id="category"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option>--SELECT--</option>
                        {fieldTypes?.map((fieldType, index) => (
                          <option key={index} value={fieldType?.value}>
                            {fieldType?.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="grid gap-4 mb-4 sm:grid-cols-1">
                    {/* Text */}
                    {newFieldType == "text" && (
                      <div className="w-full">
                        <label
                          htmlFor={newFieldType?.name}
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Value
                        </label>

                        <textarea
                          required
                          value={newTextValue}
                          onChange={(e) => setNewTextValue(e.target.value)}
                          id="description"
                          rows="5"
                          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Write your text here..."
                        ></textarea>
                      </div>
                    )}

                    {/* Multivalued attributes */}
                    {newFieldType == "attributes" && (
                      <div className="w-full">
                        <label
                          htmlFor={newFieldType?.name}
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Values
                        </label>

                        <CreatableSelect
                          required
                          components={components}
                          inputValue={inputValue}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => setValue(newValue)}
                          onInputChange={(newValue) => setInputValue(newValue)}
                          onKeyDown={handleKeyDown}
                          placeholder="Type something and press enter..."
                          value={value}
                        />
                      </div>
                    )}

                    {/* File Input */}
                    {newFieldType == "file" && (
                      <div>
                        <label
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          htmlFor="file_input"
                        >
                          {newFieldType?.name}
                        </label>
                        <input
                          required
                          multiple
                          onChange={handleNewFileChange}
                          class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                          aria-describedby="file_input_help"
                          id="file_input"
                          type="file"
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row-reverse gap-2">
                    <button
                      type="submit"
                      className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    >
                      {addingField ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Add"
                      )}
                    </button>
                    <button
                      onClick={() => setAddField(false)}
                      type="button"
                      className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
