import React, { useEffect, useRef, useState } from "react";
import clockDanger from "../assets/watch-2.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber, makeAuthenticatedRequest } from "../utils";
import Moment from "react-moment";
import { ENDPOINT_ORDERS } from "../routes";
import ChangeStatusModal from "./ChangeStatusModal";
import { Pagination } from "./Pagination";
import moment from "moment";

const ALL_TAB = null;
const NEW_TAB = "new";
const CONFIRMED_TAB = "confirmed";
const COMPLETE_TAB = "complete";
const CANCELLED_TAB = "cancelled";
const DISPATCHED_TAB = "dispatched";
const DELIVERED_TAB = "delivered";
const REQUIRES_PREPAYMENT_TAB = "Requires prepayment";
const TO_BE_EXCHANGED_TAB = "To be exchanged";
const EXCHANGE_COMPLETE_TAB = "Exchange complete";
const REJECTED_TAB = "Rejected";
const REJECT_COMPLETED_TAB = "Reject Completed";

function Orders() {
  const navigate = useNavigate();

  let currentUrlParams = new URLSearchParams(window.location.search);
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams);

  const [processing, setProcessing] = useState(true);
  const [processingStatus, setProcessingStatus] = useState("");
  const [currentTab, setCurrentTab] = useState(ALL_TAB);
  const [changeStatusModal, setchangeStatusModal] = useState("");
  const [cancellationModal, setCancellationModal] = useState(false);
  const [newCount, setNewCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);
  const [dispatchedCount, setDispatchedCount] = useState(0);
  const [toBeRepaidCount, setToBeRepaidCount] = useState(0);

  const [requiresprepaymentCount, setRequiresprepaymentCount] = useState(0);
  const [tobeexchangedCount, setTobeexchangedCount] = useState(0);
  const [exchangecompleteCount, setExchangecompleteCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [rejectCompletedCount, setRejectCompletedCount] = useState(0);

  const [toBeReplacedCount, setToBeReplacedCount] = useState(0);
  const [toBeReturnedCount, setToBeReturnedCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  let s = searchParams.get("s") !== null ? searchParams.get("s") : "";
  const [search, setSearch] = useState(s);

  const [currentPage, setCurrentPage] = useState(
    currentUrlParams.has("page") ? Number(currentUrlParams.get("page")) : 1
  );

  const [perPage, setPerPage] = useState(16);
  const [itemCount, setItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedPartner, setSelectedPartner] = useState();
  const [deliveryPartners, setDeliveryPartners] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [open, setOPen] = useState([]);
  const [remarks, setRemarks] = useState();

  const [showTabs, setShowTabs] = useState(false);
  const [currentOrder, setCurrentOrder] = useState();
  const [sendDispatchSMS, setSendDispatchSMS] = useState(true);

  const cancelRef = useRef();
  const actionRef = useRef();
  const confirmRef = useRef();

  const fetchDeliveryPartners = () => {
    makeAuthenticatedRequest(`/oms/delivery-partners/`)
      .then((response) => response.json())
      .then((data) => {
        setDeliveryPartners(data.results);
      });
  };

  useEffect(() => {
    if (deliveryPartners && deliveryPartners.length > 0) {
      setSelectedPartner(deliveryPartners[0].id);
    }
  }, [deliveryPartners]);

  const handleShowOrderChange = (orderID) => {
    const openState = open.slice();
    const index = openState.indexOf(orderID);

    if (index >= 0) {
      openState.splice(index, 1);
      setOPen(openState);
    } else {
      openState.push(orderID);
      setOPen(openState);
    }
  };

  const closeChangeModal = () => {
    setchangeStatusModal("");
  };

  const generateMapURL = (address) => {
    const params = {
      q: address,
    };
    const searchParams = new URLSearchParams(params).toString();
    return `https://maps.google.com/?${searchParams}`;
  };

  const [ordersData, setOrdersData] = useState(null);
  const fetchOrders = (page) => {
    const params = {
      s: search,
      page: page,
      per_page: perPage,
    };
    if (currentTab) {
      params.status = currentTab;
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/orders/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        setOrdersData(data);
        setCurrentPage(data.page);
        setPerPage(data.per_page);
        setItemCount(data.count);
        setTotalItems(data.count);
        setNewCount(data.new_count);
        setCompleteCount(data.complete_count);
        setDeliveredCount(data.delivered_count);
        setConfirmedCount(data.confirmed_count);
        setCancelledCount(data.cancelled_count);
        setDispatchedCount(data.dispatched_count);
        setToBeRepaidCount(data.to_be_repaid_count);
        setToBeReplacedCount(data.to_be_replaced_count);
        setToBeReturnedCount(data.to_be_returned_count);

        // setRequiresprepaymentCount(data.requires_prepayment_count)
        // setTobeexchangedCount(data.to_be_exchanged_count)
        // setExchangecompleteCount(data.exchange_complete_count)
        // setRejectedCount(data.rejected_count)
        // setRejectCompletedCount(data.reject_completed_count)

        setProcessing(false);
      });
  };

  const updateorderStatus = (order, status, remarks, reason, partner) => {
    setProcessingStatus(order.id);
    const params = {
      status: status,
    };
    if (remarks) {
      if (reason) params.message = `${reason}: ${remarks}`;
      else {
        params.message = remarks;
      }
    }
    if (status == "Dispatched" && partner) {
      params.delivery_partner = partner;
      params.date_disptached = getCurrentDateTime();
    }

    makeAuthenticatedRequest(
      `/oms/order/${order.id}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        setProcessingStatus("");
        fetchOrders(currentPage);
      });
  };
  const submitSearch = () => {
    if (search) {
      fetchOrders(currentPage);
    }
  };
  const handleSendSMSChange = () => {
    setSendDispatchSMS(!sendDispatchSMS);
  };

  const updateorderDispatchedStatus = (order, message, deliveryPartner) => {
    const params = {
      status: "Dispatched",
      delivery_partner: deliveryPartner,
    };
    if (message) {
      params.message = message;
    }
    makeAuthenticatedRequest(
      `/oms/order/${order.id}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        fetchOrders(currentPage);
      });
  };
  const statusOptions = [
    { name: "New", value: "New", label: "New", color: "#B9E38D" },
    {
      name: "Confirmed",
      value: "Confirmed",
      label: "Confirmed",
      color: "#54D3CA",
    },
    {
      name: "Dispatched",
      value: "Dispatched",
      label: "Dispatched",
      color: "#8CD1FC",
    },
    {
      name: "Delivered",
      value: "Delivered",
      label: "Delivered",
      color: "#3E5DDD",
    },

    {
      name: "Requires prepayment",
      value: "Requires prepayment",
      label: "Requires prepayment",
      color: "#576298",
    },
    {
      name: "To be exchanged",
      value: "To be exchanged",
      label: "To be exchanged",
      color: "#F0A6A5",
    },
    {
      name: "Exchange complete",
      value: "Exchange complete",
      label: "Exchange complete",
      color: "#059D5F",
    },
    {
      name: "Rejected",
      value: "Rejected",
      label: "Rejected",
      color: "#FCB28E",
    },
    {
      name: "Reject Completed",
      value: "Reject Completed",
      label: "Reject Completed",
      color: "#F0A6A5",
    },
    {
      name: "Complete",
      value: "Complete",
      label: "Complete",
      color: "#54D3CA",
    },
    {
      name: "Cancelled",
      value: "Cancelled",
      label: "Cancelled",
      color: "#DE6972",
    },
  ];

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentTab]);

  // const [multiSelectOptions] = useState(options)
  const getCurrentDateTime = () => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    return currentDateTime;
  };

  const pageChanged = (pageNumber) => {
    currentUrlParams.set("page", pageNumber);
    currentUrlParams.set("tab", "orders");

    setCurrentPage(Number(currentUrlParams.get("page")));
    setUrlParams(currentUrlParams);
    fetchOrders(Number(currentUrlParams.get("page")));
  };

  const handleOrderStatusChange = (order, status, remarks, reason, partner) => {
    updateorderStatus(order, status, remarks, reason, partner);
  };

  const orderStatusChangeDialog = (order, status) => {
    setOPen([]);
    setCurrentOrder(order);
    setchangeStatusModal(status);
  };

  useEffect(() => {
    fetchOrders(currentPage);
    fetchDeliveryPartners();
  }, [Number(currentUrlParams.get("page"))]);

  useEffect(() => {
    let handler = (e) => {
      if (cancelRef.current && !cancelRef.current.contains(e.target)) {
        setCancellationModal(false);

      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let actionhandler = (e) => {
      if (actionRef.current && !actionRef.current.contains(e.target)) {
        setOPen([]);

      }
    };
    document.addEventListener("mousedown", actionhandler);

    return () => {
      document.removeEventListener("mousedown", actionhandler);
    };
  });

  useEffect(() => {
    let confirmHandler = (e) => {
      if (confirmRef.current && !confirmRef.current.contains(e.target)) {
        setchangeStatusModal("");

      }
    };
    document.addEventListener("mousedown", confirmHandler);

    return () => {
      document.removeEventListener("mousedown", confirmHandler);
    };
  });

  return (
    <>
      {processing && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      )}
      <>
        <div className="w-full max-h-auto md:max-h-screen ">
          <div className="mt-2 mb-2">
            <form
              className="flex items-start md:items-center font-satoshi"
              method="get"
            >
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div
                  onClick={submitSearch}
                  className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  required
                  value={search}
                  type="text"
                  name="s"
                  id="simple-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    "
                  placeholder="Search an order"
                />
              </div>
            </form>
          </div>

          <div
            onClick={() => setShowTabs(!showTabs)}
            className="flex flex-row md:hidden cursor-pointer py-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
            <p className="font-satoshi text-[#030A1A] text-[16px] md:text-[20px] font-semibold">
              Nav
            </p>
          </div>

          {/* New-confirmed-dispatched-delivered-to be returned-return complete-to be exchanged-exchange complete- canceled */}
          <div className="flex flex-row gap-2 mt-4 overflow-x-scroll scrollbar-hide">
            <div
              onClick={() => setCurrentTab(NEW_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === NEW_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              New
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {newCount}
              </span>
            </div>
            <div
              onClick={() => setCurrentTab(CONFIRMED_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === CONFIRMED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Confirmed
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {confirmedCount}
              </span>
            </div>
            <div
              onClick={() => setCurrentTab(DISPATCHED_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === DISPATCHED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Dispatched
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {dispatchedCount}
              </span>
            </div>
            <div
              onClick={() => setCurrentTab(DELIVERED_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === DELIVERED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Delivered
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {deliveredCount}
              </span>
            </div>
            <div
              onClick={() => setCurrentTab(TO_BE_EXCHANGED_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === TO_BE_EXCHANGED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              To be exchanged
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {tobeexchangedCount}
              </span>
            </div>

            <div
              onClick={() => setCurrentTab(EXCHANGE_COMPLETE_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === EXCHANGE_COMPLETE_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Exchange complete
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {exchangecompleteCount}
              </span>
            </div>

            <div
              onClick={() => setCurrentTab(COMPLETE_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === COMPLETE_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              {" "}
              Complete
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {completeCount}
              </span>
            </div>

            <div
              onClick={() => setCurrentTab(CANCELLED_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === CANCELLED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Cancelled
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {cancelledCount}
              </span>
            </div>

            <div
              onClick={() => setCurrentTab(REQUIRES_PREPAYMENT_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === REQUIRES_PREPAYMENT_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Requires prepayment
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {requiresprepaymentCount}
              </span>
            </div>

            <div
              onClick={() => setCurrentTab(REJECTED_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === REJECTED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Rejected
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {rejectedCount}
              </span>
            </div>

            <div
              onClick={() => setCurrentTab(REJECT_COMPLETED_TAB)}
              className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === REJECT_COMPLETED_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              Reject Completed
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {rejectCompletedCount}
              </span>
            </div>
            <div
              onClick={() => setCurrentTab(ALL_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === ALL_TAB
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
            >
              {" "}
              All
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
                {totalItems}
              </span>
            </div>
          </div>

          <div
            className={` ${showTabs ? "" : "hidden"
              } md:flex flex-col md:flex-row gap-2 mt-4`}
          ></div>
          <div className="flex flex-col">
            <div className="flex flex-col ">
              {ordersData && !processing ? (
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                  <table class="table-auto w-full  ">
                    <thead className="pl-2">
                      <tr className="pl-1 border-b border-gray-200">
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          =
                        </th>
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          Order #
                        </th>
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          Date
                        </th>
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          Customer
                        </th>
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          Order Total(Ksh)
                        </th>
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          Courier
                        </th>
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersData.results.map((order) => (
                        <tr className="py-2 px-1 border-b border-b-gray-200 relative">
                          <td className="pl-2"></td>
                          <td
                            onClick={() =>
                              navigate(`${ENDPOINT_ORDERS}/${order.id}`)
                            }
                            className="py-5 px-1 text-xs md:text-[16px] text-left cursor-pointer font-satoshi"
                          >
                            <span className="hover:underline">
                              #{order.order_id}
                            </span>
                          </td>
                          <td className="py-2 px-1  flex-col text-left font-satoshi text-[#808491]">
                            <div className=" text-[10px] md:text-[14px] ">
                              <Moment format="MMM, DD LT" className="flex">
                                {order.date_created}
                              </Moment>
                            </div>
                            <div className="flex">
                              {order.status &&
                                (order.status != "Refunded" ||
                                  order.status != "Complete" ||
                                  order.status != "Cancelled" ||
                                  order.status != "Delivered" ||
                                  order.status != "To be replaced" ||
                                  order.status != "To be returned") && (
                                  <div className="flex pt-1">
                                    <img
                                      src={clockDanger}
                                      className="h-2 md:h-4"
                                    />

                                    <Moment
                                      className="text-[10px] md:text-[14px] text-[#BF3054]"
                                      fromNow
                                    >
                                      {order.date_created}
                                    </Moment>
                                  </div>
                                )}
                            </div>
                          </td>
                          <td className="py-4 px-1 text-xs md:text-[16px] text-left font-satoshi">
                            {order.passport.first_name
                              ? `${order.passport.first_name} ${order.passport.last_name}`
                              : order.passport.name}
                            <br />
                            <a
                              className="text-cyan-500 hover:underline"
                              href={`tel:+${order.passport.phone_number}`}
                            >
                              +{order.passport.phone_number}
                            </a>
                            {order.shipping_address &&
                              order.shipping_address.town && (
                                <>
                                  <br />
                                  <a
                                    className="text-cyan-500 hover:underline"
                                    target="_blank"
                                    href={generateMapURL(
                                      order.shipping_address.town
                                    )}
                                  >
                                    {order.shipping_address.town}
                                  </a>
                                </>
                              )}
                          </td>
                          <td className="py-2 px-1 text-xs md:text-[16px] text-left font-satoshi">
                            {order?.totals > 0
                              ? "Ksh. " +
                              formatNumber(
                                order?.totals,
                                0,
                                0
                              )
                              : "-"}
                              <br/>
                            {order?.num_lines} {order?.num_lines == 1 ? "item" : "items"}
                          </td>
                          <td className="py-2 px-1 text-xs md:text-[16px] text-left font-satoshi">
                            {order.delivery_partner ? (
                              <>
                                {order.delivery_partner.name}
                                <br />
                                <a
                                  className="text-cyan-500 hover:underline"
                                  href={`tel:+${order.delivery_partner.phone_number}`}
                                >
                                  +{order.delivery_partner.phone_number}
                                </a>
                              </>
                            ) : (
                              <>-</>
                            )}
                          </td>
                          <td className="py-2 px-1 text-xs md:text-[16px] cursor-pointer">
                            <div
                              onClick={() => handleShowOrderChange(order.id)}
                              className={
                                "text-xs flex flex-row cursor-pointer justify-between relative rounded px-2 py-2 font-satoshi text-center " +
                                (order.status === "New"
                                  ? "bg-[#B9E38D]"
                                  : order.status === "Confirmed"
                                    ? "bg-[#54D3CA]"
                                    : order.status === "Dispatched"
                                      ? "bg-[#8CD1FC]"
                                      : order.status === "Delivered"
                                        ? "bg-[#3E5DDD]"
                                        : order.status === "Requires prepayment"
                                          ? "bg-[#576298]"
                                          : order.status === "To be exchanged"
                                            ? "bg-[#F0A6A5]"
                                            : order.status === "Exchange complete"
                                              ? "bg-[#059D5F]"
                                              : order.status === "Rejected"
                                                ? "bg-[#FCB28E]"
                                                : order.status === "Reject Completed"
                                                  ? "bg-[#F0A6A5]"
                                                  : order.status === "Complete"
                                                    ? "bg-[#059D5F]"
                                                    : order.status === "Cancelled"
                                                      ? "bg-[#DE6972]"
                                                      : "bg-[#8d252e]")
                              }
                            >
                              {processingStatus
                                ? "Processing..."
                                : order?.status}
                              <div className="">
                                {
                                  open.includes(order.id) ? (
                                    // arrow-down
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className=" h-4"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className=" h-4"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                      />
                                    </svg>
                                  )
                                  // arrow-up
                                }
                              </div>
                            </div>

                            {open.includes(order.id) && (
                              <div
                                className={`bg-white border-4 border-white rounded-md p-1 flex flex-col gap-2 w-[17%] hide-scrollbar overflow-y-scroll absolute top-14 z-50 shadow-2xl right-2`}
                              >
                                {statusOptions
                                  .filter(
                                    (statusOption) =>
                                      statusOption.name !== order.status
                                  )
                                  .map((status) => (
                                    <div
                                      onClick={() =>
                                        orderStatusChangeDialog(
                                          order,
                                          status.name
                                        )
                                      }
                                      className={`bg-[${status.color}] rounded-md px-4 py-1.5 cursor-pointer`}
                                    >
                                      <p className="text-[13px] font-satoshi cursor-pointer">
                                        {status.name}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {ordersData.results.length > 0 && (
                    <Pagination
                      pageChanged={pageChanged}
                      totalItems={totalItems}
                      itemsPerPage={perPage}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              ) : (
                <>
                  {!processing && (
                    <p className="font-satoshi text-[18px] py-6">
                      No orders to display
                    </p>
                  )}
                </>
              )}
            </div>
          </div>

          {changeStatusModal && currentOrder && (
            <ChangeStatusModal
              delPartners={deliveryPartners}
              order={currentOrder}
              newStatus={changeStatusModal}
              handleClose={closeChangeModal}
              handleSubmit={handleOrderStatusChange}
              changeTo={
                changeStatusModal === "New"
                  ? "New"
                  : changeStatusModal === "Confirmed"
                    ? "Confirmed"
                    : changeStatusModal === "Dispatched"
                      ? "Dispatched"
                      : changeStatusModal === "Delivered"
                        ? "Delivered"
                        : changeStatusModal === "Requires prepayment"
                          ? "Requires prepayment"
                          : changeStatusModal === "To be exchanged"
                            ? "To be exchanged"
                            : changeStatusModal === "Exchange complete"
                              ? "Exchange complete"
                              : changeStatusModal === "Rejected"
                                ? "Rejected"
                                : changeStatusModal === "Reject Completed"
                                  ? "Reject Completed"
                                  : changeStatusModal === "Complete"
                                    ? "Complete"
                                    : changeStatusModal === "Cancelled"
                                      ? "Cancelled"
                                      : ""
              }
            />
          )}
        </div>
      </>
    </>
  );
}

export default Orders;
